.menu {
  line-height: 64px;
}

.content {
  padding: 0 1em;
}

.contentDiv {
  background-color: #fff;
  min-height: 280px;
}

.footer {
  text-align: center;
}
