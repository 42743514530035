$desktop: 960px;
$mobile: 959px;

.screenshotClass {
  margin-left: auto;
  margin-right: auto;
  display: block;
  cursor: pointer;
}

.screenshotDiv {
  padding-top: 3em;
  padding-bottom: 3em;
}

@media (min-width: $desktop) {
  .screenshotClass {
    width: 70%;
  }
}

@media (max-width: $mobile) {
  .screenshotClass {
    width: 90%;
  }
}
