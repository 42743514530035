$desktop: 960px;
$mobile: 959px;

.cardClass {
  margin: auto;
}

.cardDiv {
  width: 100%;
  padding-top: 1em;
  padding-bottom: 3em;
}

@media (min-width: $desktop) {
  .cardDiv {
    padding-left: 5em;
    padding-right: 5em;
  }

  .cardClass {
    width: 50%;
  }
}

@media (max-width: $mobile) {
  .cardDiv {
    padding-left: 1em;
    padding-right: 1em;
  }

  .cardClass {
    width: 90%;
  }
}
